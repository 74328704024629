export default {
    promptConfirmation: ({title, text, type}) => {
        const config = {
            title: title || '',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }

        if (text) {
            config.text = text
        }
        if (type) {
            config.type = type
        }

        return Swal.fire(config)
    },

    notifiy: function({ title, text, type, showConfirmButton = true }) {
        const config = {
            position: 'center',
            type: type,
            title: title || '',
            showConfirmButton: showConfirmButton
        }

        if (text) {
            config.text = text
        }

        return Swal.fire(config);
    }
}